import React, { useEffect, useState } from "react";
import Page from "..";
import instancia from "../../instancia";
import Loading from "../../components/Loading";
import useToast from "../../state/hooks/useToast";
import Forbbiden from "../Error/forbbiden";
import ServerError from "../Error/serverError";

const PageChatSensebot = () => {
    const [url, setUrl] = useState('');
    const { addToast } = useToast();
    const [error, setError] = useState<number>();

    useEffect(() => {
        instancia
            .get('/sensebot?code=_VFGT9joepL3KeKfxlRDVJF-Z8JXNQznUxP0CiIfXHlYAzFuV7VlsQ%3D%3D', { withCredentials: true })
            .then(res => {
                const iframe = res.data[0]?.iframe
                if(iframe) {
                    setUrl(res.data[0].iframe)
                    return
                }
            })
            .catch(err => {
                const status = err.response?.status;
                setError(status || 0)
            })
    }, [])

    return (
        <Page
            conteudo={({ open }) =>
                <>
                    {
                        error ?
                            error === 403 ? <Forbbiden /> : <ServerError /> :
                            url.length !== 0 ?
                                <iframe
                                    src={url}
                                    style={{
                                        width: '100%',
                                        margin: '1rem 0px',
                                        height: '90%',
                                        borderRadius: '.5rem',
                                        backgroundColor: 'transparent',
                                        border: 'none'
                                    }}
                                ></iframe> : <Loading />
                    }
                </>
            }
        />
    )
}

export default PageChatSensebot;