import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import Botao from "../../components/Botao";
import { handleContato } from "../../utils";

const Forbbiden = () => {
    const navigate = useNavigate();
    
    return(
        <div style={{ margin: 'auto', marginTop: '10%', width: '470px', height: '160px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
            <Alert severidade="info" titulo="Acesso Negado" texto="Parece que você não tem acesso a essa página. Fale com o seu ponto de contato da CAFS para poder ter acesso a esse conteudo" />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Botao label="Entrar em contato" variant="success" width="49%" onClick={handleContato} />
                <Botao label="Voltar para Home" variant="secondary" width="49%" onClick={() => navigate('/home')} />
            </div>
        </div>
    )
}

export default Forbbiden;