import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { toastState } from '../../state/atom';
import Toast from '../../components/Toast';
import useToast from '../../state/hooks/useToast';

const ToastContainer = styled.div`
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all 0.4s ease;
`;

const ToastManager = () => {
    const toasts = useRecoilValue(toastState);
    const { removeToast } = useToast();

    return (
        <ToastContainer>
            {toasts.map((toast, index) => (
                <Toast
                    key={index}
                    message={toast.message}
                    severidade={toast.severidade}
                    onClose={() => removeToast(index)}
                />
            ))}
        </ToastContainer>
    );
};

export default ToastManager;
