import React, { useEffect, useState } from "react";
import useTheme from "../../state/hooks/useTheme";
import { ToolbarButtonRow, getTheme } from '@grafana/ui';
import { ReactComponent as RefreshIcon } from '../../assets/images/refresh.svg';
import styled, { keyframes, css } from "styled-components";
import { style } from "../../styles";
import Select from '../../components/Select';
import Loading from "../../components/Loading";
import Botao from "../../components/Botao";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<{ theme: any }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    & button {
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
        border: 1px solid ${({ theme }) => theme.colors.border1};
        font-family: ${style.font.family.light};

        &:hover {
            background-color: ${({ theme }) => theme.colors.bg1};
            border: 1px solid ${({ theme }) => theme.colors.border2};
            color: ${({ theme }) => theme.colors.text};
            cursor: pointer;
        }
    }
`;

const RotatingIcon = styled(RefreshIcon) <{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && css`
    animation: ${spin} 1s linear infinite;
  `}
`;

const timeFilters: { [key: string]: string } = {
    "Últimos 5 minutos": "now-5m",
    "Últimos 15 minutos": "now-15m",
    "Últimos 30 minutos": "now-30m",
    "Última 1 hora": "now-1h",
    "Últimas 3 horas": "now-3h",
    "Últimas 6 horas": "now-6h",
    "Últimas 12 horas": "now-12h",
    "Últimas 24 horas": "now-24h",
    "Últimos 2 dias": "now-2d",
    "Últimos 7 dias": "now-7d",
    "Últimos 30 dias": "now-30d",
    "Últimos 90 dias": "now-90d",
};

const refresh: { [key: string]: string | undefined } = {
    "Desligado": undefined,
    "A cada 15 minutos": "15m",
    "A cada 30 minutos": "30m",
    "A cada 1 hora": "1h",
    "A cada 2 horas": "2h",
    "A cada 1 dia": "1d",
};

const Dashboard: React.FC<{ titulo: string, token: string, dataDashboard: { title: string, tags: { value: string, tag: string }[], url: string }[], filterData: boolean }> = ({ dataDashboard, titulo, token, filterData }) => {
    const [dados, setDados] = useState<{ title: string, tags: { value: string, tag: string }[], url: string }>();
    const { theme } = useTheme();
    const themeGrafana = getTheme(theme);
    const [filter, setFilter] = useState("Últimos 5 minutos");
    const [autoRefresh, setAutoRefresh] = useState<string>("Desligado");

    const [refreshKey, setRefreshKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const filterDados = dataDashboard.find(value => value.title === titulo);
        setDados(filterDados);
    }, [titulo, theme, dataDashboard]);

    const handleRefresh = () => {
        setIsLoading(true);
        setRefreshKey(prevKey => prevKey + 1);
    };


    const iframeUrl = dados ?
        `https://grafana.cafs.com.br${dados.url}?orgId=1&kiosk&from=${timeFilters[filter]}&to=now&auth_token=${token}&theme=${theme}&refreshKey=${refreshKey}&${refresh[autoRefresh] ? `refresh=${refresh[autoRefresh]}` : ''}`
        : '';

    return (
        <Container theme={themeGrafana}>
            <ToolbarButtonRow>
                <Botao
                    label="Atualizar dados"
                    disabled={isLoading}
                    icon={<RotatingIcon width={16} height={16} isLoading={isLoading} />}
                    onClick={handleRefresh}
                    variant="secondary"
                    width="auto"
                />
                {
                    filterData ?
                        <Select
                            items={Object.keys(timeFilters)}
                            label="Filtro por tempo"
                            onSelect={(e) => {
                                handleRefresh();
                                setFilter(e)
                            }}
                            padrao={filter}
                        /> : null
                }
                <Select
                    items={Object.keys(refresh)}
                    label="Atualização automática"
                    onSelect={(e) => {
                        handleRefresh();
                        setAutoRefresh(e);
                    }}
                    padrao={autoRefresh}
                />
            </ToolbarButtonRow>
            {
                dataDashboard.length !== 0 && dados ?
                    <iframe
                        key={refreshKey}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            marginTop: '1rem',
                        }}
                        src={iframeUrl}
                        onLoad={() => setIsLoading(false)}
                    />
                    : <Loading />
            }
        </Container>
    )
}

export default Dashboard;
