import styled from "styled-components";
import { style } from "../../styles";
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";

const hashCode = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return hash;
};

const getInitials = (email: string) => {
    const parts = email.split('@')[0].split('.');
    return parts.map(part => part[0]?.toUpperCase()).join('');
};

const intToRGB = (i: number) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
};

const AvatarContainer = styled.div<{ theme: TTheme, bgColor: string, size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 4px;
    background-color: ${props => props.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.size * 0.41}px;
    font-family: ${style.font.family.semiBold};
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    cursor: pointer;
    transition: all .4s;

    &:hover {
        box-shadow: 0px 0px 4px 2px ${style.colors.secondary};
    }
`;


const Avatar = ({ email, size }: { email: string, size: number }) => {
    const { theme } = useTheme();
    const initials = getInitials(email);
    const bgColor = `#${intToRGB(hashCode(email))}`;

    return (
        <AvatarContainer
            size={size}
            theme={theme}
            bgColor={bgColor}
        >
            {initials}
        </AvatarContainer>
    );
};

export default Avatar;