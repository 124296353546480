import Alert from "../../components/Alert";
import Botao from "../../components/Botao";
import { handleSuportPortal } from "../../utils";

const ServerError = () => {

    return(
        <div style={{ margin: 'auto', marginTop: '10%', width: '470px', height: '180px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
            <Alert severidade="critical" titulo="Aconteceu algum erro" texto="Parece que estamos com problemas. Tente recarregar a página, se o erro permanecer abra um chamado no suporte para resolvermos seu problema o mais rápido possível" />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Botao label="Abrir chamado" variant="secondary" width="49%" onClick={handleSuportPortal} />
                <Botao label="Regarregar Página" variant="success" width="49%" onClick={() => window.location.reload()} />
            </div>
        </div>
    )
}

export default ServerError;