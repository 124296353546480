import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";
import { Link, To } from "react-router-dom";

interface IContainer {
    open: boolean
    theme: TTheme
    selecionado?: boolean
}

const Container = styled(Link)<IContainer>`
    width: ${props => props.open ? '100%' : '44px'};
    height: 44px;
    box-sizing: border-box;
    padding: ${props => props.open ? '0 .6125rem' : '0 0'};
    background-color: ${props => !props.selecionado ? "" : props.theme === 'light' ? style.colors.light['bg-primary'] : style.colors.dark["bg-primary"]};
    border-radius: 0;
    border-top-right-radius: ${props => props.open ? '4px' : 'none'};
    border-bottom-right-radius: ${props => props.open ? '4px' : 'none'};
    border-radius: ${props => props.open ? 'none' : '4px'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.open ? 'start' : 'center'};
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    transition: width .4s ease;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: ${props => props.selecionado ? props.theme === 'light' ? style.colors.light['bg-primary'] : style.colors.dark["bg-primary"] : `${style.colors.secondary}70`};
    }
`

const Label = styled.p<{ open: boolean }>`
    font-size: 14px;
    font-family: ${style.font.family.light};
    text-align: center;
    transition: opacity .3s ease;
    margin-left: .5rem;
    display: ${props => props.open ? "flex" : "none"};
`

interface IItemMenu {
    icone: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    iconeColodiro: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    label: string | boolean
    link: To
    open: boolean
    selecionado?: boolean
}

const ItemMenu = ({ icone: IconSvg, iconeColodiro: IconSvgColorido, label, link, open, selecionado }: IItemMenu) => {
    const [icon, setIcon] = useState(<IconSvg />);
    const { theme } = useTheme();

    return (
        <Container
            to={link}
            theme={theme}
            open={open}
            selecionado={selecionado}
            onMouseLeave={() => setIcon(<IconSvg />)}
            onMouseEnter={() => setIcon(<IconSvgColorido />)}
        >
            {selecionado ? <IconSvgColorido /> : icon}
            {/* <Icone theme={theme}>
            </Icone> */}
            <Label open={open}>{label}</Label>
        </Container>
    );
};

export default ItemMenu;
