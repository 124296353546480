import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import useTheme from '../../state/hooks/useTheme';
import { NavLink } from "react-router-dom";

const Card = styled(NavLink) <{ theme: TTheme }>`
    width: 32%;
    height: auto;
    background-color: ${({ theme }) => theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    border-radius: .5rem;
    margin: .25rem .5%;
    color: ${({ theme }) => theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    box-sizing: border-box;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;

    & h2 {
        font-family: ${style.font.family.medium};
        font-size: 1rem;
        text-align: center;
        margin-bottom: .5rem;
    }

    & p {
        font-family: ${style.font.family.regular};
        font-size: 14px;
        margin-bottom: 2rem;
        text-align: justify;
    }

    & span {
        font-family: ${style.font.family.light};
        font-size: 14px;
    }

    & div {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

`;

const Status = styled.div<{ status: "Concluido" | "Pendente" | "Em andamento" | "Cancelado" | "Validação" }>`
    color: #fff;
    width: auto;
    padding: .5rem 1rem;
    background-color: ${props => props.status === 'Concluido' ?
        style.colors.success :
        props.status === 'Pendente' ?
            style.colors.warning :
            props.status === 'Em andamento' ?
                style.colors.info :
                props.status === 'Cancelado' ?
                    style.colors.critical : style.colors.primary
    };;
    border-radius: 1rem;
    font-family: ${style.font.family.semiBold};
    font-size: 12px;
`

interface ICardProjeto {
    chave: string
    titulo: string
    descricao: string
    data: string
    status: "Concluido" | "Pendente" | "Em andamento" | "Cancelado" | "Validação"
}

const CardProjeto = ({ chave, titulo, descricao, data, status }: ICardProjeto) => {
    const { theme } = useTheme();
    return (
        <Card key={chave} to={`?projeto=${chave}`} theme={theme}>
            <h2>{titulo}</h2>
            <p>{descricao}</p>
            <div>
                <span>{data}</span>
                <Status status={status}>{status}</Status>
            </div>
        </Card>
    );
};

export default CardProjeto;