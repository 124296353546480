import React from 'react';
import styled from 'styled-components';
import { style } from '../../styles';
import { TTheme } from '../../types/types';
import useTheme from '../../state/hooks/useTheme';

const SwitchContainer = styled.div`
  width: 40px;
`;

const HiddenSwitch = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
`;

const Label = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
  padding: 1px; /* Adjusted for smaller height */
  width: 40px;
  height: 20px; /* Maximum height of 20px */
  background-color: #dddddd;
  border-radius: 20px;
  transition: background 0.4s;
  
  &::before,
  &::after {
    display: block;
    position: absolute;
    content: "";
  }

  &::before {
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background-color: #fff;
    border-radius: 20px;
    transition: background 0.4s;
  }

  &::after {
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 16px;
    background-color: #dddddd;
    border-radius: 16px;
    transition: margin 0.4s, background 0.4s;
  }
`;

const Switch = styled(HiddenSwitch)<{theme: TTheme}>`
  & + ${Label} {
    &::before,
    &::after {
      top: 1px;
      left: 1px;
      bottom: 1px;
      right: 1px;
      background-color: ${props => props.theme === 'light' ? style.colors.light['bg-secondary'] : style.colors.dark['bg-secondary']};
      border-radius: 20px;
      transition: background 0.4s;
    }

    &::after {
      top: 3px;
      left: 3px;
      bottom: 2px;
      width: 16px;
      height: 16px;
      background-color: #dddddd;
      border-radius: 16px;
      transition: margin 0.4s, background 0.4s;
    }
  }

  &:checked + ${Label} {
    background-color: ${style.colors.success};

    &::after {
      margin-left: 20px;
      background-color: ${style.colors.success};
    }
  }
`;

interface ISwitchButton {
    value: boolean
    onChange: React.ChangeEventHandler<HTMLInputElement>
}

const SwitchButton = ({ value, onChange }: ISwitchButton) => {
    const { theme } = useTheme();

    return(
        <SwitchContainer>
            <Switch checked={value} theme={theme} onChange={onChange} id="switch-flat" className="switch switch--flat" />
            <Label htmlFor="switch-flat"></Label>
        </SwitchContainer>
    )
}

export default SwitchButton;
