import React, { useEffect, useState } from "react";
import Page from "..";
import styled, { keyframes } from "styled-components";
import { ReactComponent as CommandCenter } from '../../assets/images/command-colorido.svg';
import { ReactComponent as Sensebot } from '../../assets/images/sensebot-colorido.svg';
import { ReactComponent as Rad } from '../../assets/images/rad-colorido.svg';
import { ReactComponent as Nexus } from '../../assets/images/recebiveis-colorido.svg';
import { ReactComponent as Consult } from '../../assets/images/consultoria-colorido.svg';
import useTheme from '../../state/hooks/useTheme';
import CardComponent from "../../components/Card";
import axios from "axios";
import { TTheme } from "../../types/types";
import { style } from "../../styles";
import Loading from "../../components/Loading";
import instancia from "../../instancia";
import Alert from "../../components/Alert";

const skeletonAnimation = keyframes`
    0% {
        background-position: -500px 0;
    }
    50% {
        background-position: 200px 0;
    }
`;


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: start;
    overflow: auto;
    overflow-x: hidden;
`

const CardLoad = styled.div<{ theme: TTheme }>`
    width: 200px;
    height: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    margin: 1.5rem;
    margin-left: 0px;
    text-decoration: none;

    background-image: ${props => props.theme === 'light'
        ? `linear-gradient(90deg, ${style.colors.light["bg-secondary"]} 25%, #e0e0e0 50%, ${style.colors.light["bg-secondary"]} 75%)`
        : `linear-gradient(90deg, ${style.colors.dark["bg-secondary"]} 25%, #2a2d32 50%, ${style.colors.dark["bg-secondary"]} 75%)`};
    background-size: 400% 100%;
    animation: ${skeletonAnimation} 3s ease-in-out infinite;
`;

const Conteudo = () => {
    const { theme } = useTheme();

    return (
        <Container>
            <CardComponent
                link={'/cc/dashboard'}
                theme={theme}
                imagem={CommandCenter}
                text="Command Center - Dashboard"
            />
            <CardComponent
                link={'/cc/alert'}
                theme={theme}
                imagem={CommandCenter}
                text="Command Center - Alertas"
            />
            <CardComponent
                link={'/cc/document'}
                theme={theme}
                imagem={CommandCenter}
                text="Command Center - Documentação"
            />
            <CardComponent
                link={'/cc/incident'}
                theme={theme}
                imagem={CommandCenter}
                text="Command Center - Incidentes"
            />
            <CardComponent
                link={'/sensebot/chat'}
                theme={theme}
                imagem={Sensebot}
                text="Sensebot - Chat"
            />
            <CardComponent
                link={'/sensebot/document'}
                theme={theme}
                imagem={Sensebot}
                text="Sensebot - Documentação"
            />
            <CardComponent
                link={'/rad'}
                theme={theme}
                imagem={Rad}
                text="RAD"
            />
            <CardComponent
                link={'/nexus'}
                theme={theme}
                imagem={Nexus}
                text="Nexus"
            />
            <CardComponent
                link={'/consultoria/projetos'}
                theme={theme}
                imagem={Consult}
                text="Consultoria - Projetos"
            />
            <CardComponent
                link={'/consultoria/incident'}
                theme={theme}
                imagem={Consult}
                text="Consultoria - Incidentes"
            />
        </Container>
    );
};

const PageHome = () => {

    return (
        <Page
            conteudo={({ open }) => <Conteudo />}
        />
    )
}

export default PageHome;