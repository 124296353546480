import React, { useState, useRef, useEffect } from 'react';
import BotaoIcone from '../BotaoIcone';
import useTheme from '../../state/hooks/useTheme';
import styled from 'styled-components';
import { TTheme } from '../../types/types';
import { style } from '../../styles';

interface DropdownProps {
  children: JSX.Element;
  icon: JSX.Element;
}

const Menu = styled.div<{ theme: TTheme }>`
  min-width: 150px;
  width: auto;
  max-width: 200px;
  min-height: 120px;
  height: auto;
  max-height: 220px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.theme === 'light'
      ? style.colors.light['bg-secondary']
      : style.colors.dark['bg-secondary']};
  border-radius: 0.3rem;
  position: absolute;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  box-sizing: border-box;
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: no-wrap;
  color: ${(props) =>
    props.theme === 'light'
      ? style.colors.light['font-color']
      : style.colors.dark['font-color']};
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const IconWrapper = styled.div`
    width: auto;
    height: auto;

  & > svg {
    width: 24px;
    height: 24px;
  }

`;

const Dropdown: React.FC<DropdownProps> = ({ children, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }}>
      <div onClick={() => setIsOpen(!isOpen)}>
        <BotaoIcone theme={theme} selecionado={isOpen}>
          <IconWrapper>{icon}</IconWrapper>
        </BotaoIcone>
      </div>
      {isOpen && <Menu theme={theme}>{children}</Menu>}
    </div>
  );
};

export default Dropdown;
