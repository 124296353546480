import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";
import { getTheme, InlineField, Input as InputGrafana } from "@grafana/ui";

const Container = styled.div<{ theme: any }>`
    width: auto;
    height: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & label, & input, & input::placeholder {
        font-family: ${style.font.family.light};
    }

    & label {
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
    }

    & input {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.border2};
        color: ${({ theme }) => theme.colors.text};
        transition: all .2s;
        font-size: 14px;

        &:hover {
            border: 1px solid ${({ theme }) => theme.colors.border3};
        }
    }

    & input::placeholder {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text};
    }
`

interface IInput {
    label?: string
    placeholder: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    type: string
    value?: string
}

const Input = ({ label, placeholder, onChange, onBlur, type, value }: IInput) => {
    const { theme } = useTheme();
    const themeGrafana = getTheme(theme);

    return (
        <Container theme={themeGrafana}>
            {
                label ?
                    <InlineField label={label} >
                        <InputGrafana
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder={placeholder}
                            type={type}
                        />
                    </InlineField> :
                    <InputGrafana
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder={placeholder}
                        type={type}
                    />
            }
        </Container>
    )
}

export default Input;