import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { getTheme, ToolbarButton } from "@grafana/ui";
import useTheme from "../../state/hooks/useTheme";
import { TStatus } from "../../types/types";

const Container = styled.div<{ theme: any, width: string, variant: TStatus }>`
    width: ${({ width }) => width};
    height: auto;
    
    & button {
        width: 100%;
        border-radius: 2px;
        background-color: ${({ theme, variant }) => variant === 'secondary' ? theme.colors.bg1 : style.colors[variant]};
        color: ${({ theme, variant }) => variant === 'secondary' ? theme.colors.text : variant === 'warning' ? style.colors.light["font-color"] :  theme.colors.text};
        border: 1px solid ${({ theme, variant }) => variant === 'secondary' ? theme.colors.border1 : style.colors[variant]};
        font-family: ${style.font.family.light};
        // transition: all .2s;

        &:hover {
            background-color: ${({ theme, variant }) => variant === 'secondary' ? theme.colors.bg1 : style.colors[variant]};
            border: 1px solid ${({ theme, variant }) => variant === 'secondary' ? theme.colors.border2 : style.colors[variant]};
            color: ${({ theme, variant }) => variant === 'secondary' ? theme.colors.text : variant === 'warning' ? style.colors.light["font-color"] : 'white'};
            cursor: pointer;
        }
    }
`

interface IBotao {
    label: string;
    icon?: JSX.Element;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    width: string;
    variant: TStatus;
}

const Botao = ({ label, onClick, icon, disabled, width, variant }: IBotao) => {
    const { theme } = useTheme();
    const themeGrafana = getTheme(theme);

    return (
        <Container width={width} variant={variant} theme={themeGrafana}>
            <ToolbarButton
                style={{

                }}
                disabled={disabled}
                icon={icon}
                onClick={onClick}
            >
                {label}
            </ToolbarButton>
        </Container>
    )
}

export default Botao;