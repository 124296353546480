import React from "react";
import LayoutDocumentacao from "../../../layouts/Documents";

const PageDocCommandCenter: React.FC = () => {
    return (
        <LayoutDocumentacao
            basePath="/cc/document"
            endpointData="command-center"
        />
    );
};

export default PageDocCommandCenter;
