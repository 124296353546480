import React, { useMemo } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";
import { To, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ReactComponent as Dashboard } from '../../assets/images/dashboard.svg';
import { ReactComponent as Alert } from '../../assets/images/alert.svg';
import { ReactComponent as Document } from '../../assets/images/document.svg';
import { ReactComponent as Incident } from '../../assets/images/incident.svg';
import { ReactComponent as Chat } from '../../assets/images/chat.svg';
import { ReactComponent as Projetos } from '../../assets/images/projetos.svg';

const Container = styled.div<{ theme: TTheme, open: boolean }>`
    height: 35px;
    width: ${props => props.open ? "calc(100vw - 240px)" : "calc(100vw - 70px)"};
    border-top: 1px solid ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    top: 64px;
    left: ${props => props.open ? "240px" : "70px"};
    transition: width .8s;
    padding: 1rem 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: fixed;
    z-index: 10;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    transition: all .8s;
`;

const MenuItem = styled(NavLink)<{ theme: TTheme, selecionado?: boolean, color: string }>`
    width: 200px;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: ${props => props.selecionado ? 'white' : props.theme === 'light' ? style.colors.secondary : `${style.colors.secondary}60`};
    background-color: ${props => props.selecionado ? props.color : "transparent"};
    transition: all .4s linear;
    text-decoration: none;
    margin-right: 1rem;
    border-radius: 2px;

    &:hover, &:hover > p {
        background-color: ${props => props.selecionado ? "" : props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
        color: ${props => props.selecionado ? "" : props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]}
    }
`;

const Text = styled.p<{ theme: TTheme, selecionado?: boolean }>`
    font-size: 14px;
    color: ${props => props.selecionado ? 'white' : `${style.colors.secondary}`};
    font-family: ${props => props.selecionado ? style.font.family.medium : style.font.family.regular};
    transition: all .4s linear;
    margin-left: 1rem;
`;

interface IMenuItem {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    label: string
    selecionado: boolean
    link: To
}

const getNavigationItems = (location: string): IMenuItem[] => {
    const isSelect = (link: string) => location.startsWith(link);
    const service = (link: string) => location.startsWith(link);

    if (service('/cc')) {
        return [
            { Icon: Dashboard, label: 'Dashboards', selecionado: isSelect('/cc/dashboard'), link: '/cc/dashboard' }, 
            { Icon: Alert, label: 'Alertas', selecionado: isSelect('/cc/alert'), link: '/cc/alert' }, 
            { Icon: Document, label: 'Documentação', selecionado: isSelect('/cc/document'), link: '/cc/document' }, 
            { Icon: Incident, label: 'Incidentes', selecionado: isSelect('/cc/incident'), link: '/cc/incident' }
        ];
    } else if (service('/sensebot')) {
        return [
            { Icon: Chat, label: 'Chatbot', selecionado: isSelect('/sensebot/chat'), link: '/sensebot/chat' }, 
            { Icon: Document, label: 'Documentação', selecionado: isSelect('/sensebot/document'), link: '/sensebot/document' },
        ];
    } else if (service('/consultoria')) {
        return [
            { Icon: Projetos, label: 'Projetos', selecionado: isSelect('/consultoria/projetos'), link: '/consultoria/projetos' }, 
            { Icon: Incident, label: 'Incidentes', selecionado: isSelect('/consultoria/incident'), link: '/consultoria/incident' }
        ];
    } else if (service('/rad')) {
        return [];
    } else if (service('/nexus')) {
        return [];
    }
    return [];
};

const Navegacao: React.FC <{ open: boolean }> = ({ open }) => {
    const { theme } = useTheme();
    const location = useLocation();

    const validaBasePath = (input: string) => {
        if (input.includes("cc")) {
            return style.colors.commandCenter;
        } else if (input.includes("sensebot")) {
            return style.colors.sensebot;
        } else if (input.includes("rad")) {
            return style.colors.rad;
        } else if (input.includes("nexus")) {
            return style.colors.nexus;
        } else {
            return style.colors.primary;
        }
    }

    const navegacao = useMemo(() => getNavigationItems(location.pathname), [location.pathname]);
    
    return (
        navegacao.length !== 0 ?
            <Container open={open} theme={theme}>
                {
                    navegacao.map(({ Icon, label, selecionado, link }, index) => (
                        <MenuItem color={validaBasePath(location.pathname)} to={link} key={index} selecionado={selecionado} theme={theme}>
                            <Icon width={20} height={20} />
                            <Text selecionado={selecionado} theme={theme}>{label}</Text>
                        </MenuItem>
                    ))
                }
            </Container> : null
    );
}

export default Navegacao;
