import styled, { keyframes } from "styled-components";
import { TTheme } from "../../types/types";
import useTheme from "../../state/hooks/useTheme";
import BotaoIcone from "../BotaoIcone";
import { ReactComponent as IconClose } from '../../assets/images/close.svg'
import { style } from "../../styles";

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
`;

const Container = styled.div<{ theme: TTheme }>`
    min-width: 300px;
    width: auto;
    max-width: 550px;
    height: auto;
    background-color: ${props => props.theme === 'light' ? style.colors.light['bg-secondary'] : style.colors.dark['bg-secondary']};
    border-radius: 4px;
    position: fixed;
    z-index: 10000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: calc(1rem + 22px) 1rem;
    animation: ${fadeIn} 0.3s ease-in-out; /* Animação aplicada */
`;

const ContainerBotaoIcone = styled.div<{ theme: TTheme }>`
    width: 44px;
    height: 44px;
    position: absolute;
    top: .5rem;
    right: .5rem;
    color: ${props => props.theme === 'light' ? style.colors.light['font-color'] : style.colors.dark["font-color"]};
`;

const Modal = ({ children, closeModal }: { children: JSX.Element, closeModal: React.MouseEventHandler<HTMLDivElement> }) => {
    const { theme } = useTheme();

    return(
        <Container theme={theme}>
            <ContainerBotaoIcone onClick={closeModal}>
                <BotaoIcone selecionado={false} theme={theme}>
                    <IconClose width={20} height={20} />
                </BotaoIcone>
            </ContainerBotaoIcone>
            {children}
        </Container>
    );
};

export default Modal;