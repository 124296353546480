// Page.tsx
import React, { useEffect, useState } from "react";
import SideMenu from "../containers/Sidemenu";
import Topbar from "../containers/Topbar";
import styled from "styled-components";
import { style } from "../styles";
import Navegacao from "../components/Navegacao";
import useTheme from '../state/hooks/useTheme';
import { TTheme } from "../types/types";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import useStateSubMenu from "../state/hooks/useStateSubMenu";

interface IContainer {
    open: boolean
    theme: TTheme
}

const Container = styled.div<IContainer>`
    width: ${props => props.open ? "calc(100vw - 245px)" : "calc(100vw - 75px)"};
    height: calc(100vh - 95px);
    top: 100px;
    margin-top: 5px;
    position: absolute;
    left: ${props => props.open ? "440px" : "270px"};
    left: ${props => props.open ? "245px" : "75px"};
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    box-sizing: border-box;
    padding: 1.5rem;
    border-top-left-radius: 6px;
    transition: all .8s;
    overflow: auto;
    overflow-x: hidden;
`

const Box = styled.div<IContainer>`
    width: 100vw;
    height: 100vh;
    top: 64px;
    left: ${props => props.open ? "255px" : "70px"};
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
`

interface IPage {
    conteudo: (props: { open?: boolean }) => JSX.Element;
}

const Page: React.FC<IPage> = ({ conteudo }) => {
    const storedStatus = localStorage.getItem('status-menu');
    const stateMenu = storedStatus !== null ? JSON.parse(storedStatus) : true;

    const [open, setOpen] = useState(stateMenu);
    const { theme } = useTheme();
    const location = useLocation();

    const [isAuthenticated, setAuth] = useState(true);

    useEffect(() => {
        axios
            .get(`https://areacliente-bff-eastus.azurewebsites.net/api/auth?code=QOjV9I986-NHF3aF8dUoh29o-aOLwR3ZTfPJedl6arDbAzFuscmtyw%3D%3D`, { withCredentials: true })
            .then((res) => {
                if(res.status !== 200) {
                    setAuth(false)
                } else {
                    setAuth(true);
                }
            })
            .catch(() => setAuth(false));
    }, [])

    return (
        <>
            {
                !isAuthenticated && location.pathname !== '/' ?
                <Navigate to='/' /> :
                <>
                    <Topbar open={open} onClickMenu={() => {
                        localStorage.setItem('status-menu', `${!open}`);
                        setOpen(!open)
                    }} />
                    <SideMenu open={open} />
                    <Navegacao open={open} />
                    <Box theme={theme} open={open}>
                        <Container theme={theme} open={open}>
                            {conteudo({ open })}
                        </Container>
                    </Box>
                </>
            }
        </>
    )
}

export default Page;
