import React, { useEffect, useState } from "react";
import Page from "../..";
import styled from "styled-components";
import { style } from "../../../styles";
import { TTheme } from "../../../types/types";
import useTheme from '../../../state/hooks/useTheme';
import Select from "../../../components/Select";
import CardProjeto from "../../../components/CardProejto";
import { useLocation } from "react-router-dom";
import ProjetoTimeline from "../../../containers/Timeline";
import Forbbiden from "../../Error/forbbiden";
import ServerError from "../../Error/serverError";

const Container = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    overflow: auto;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    border-radius: .5rem;
    padding: 1rem;
    box-sizing: border-box;
    flex-wrap: wrap;
`;

const ContainerFilter = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: auto;
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 1rem;
    box-sizing: border-box;
`

const BoxFilter = styled.div`
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
`

const Conteudo = () => {
    const { theme } = useTheme();
    const dadosCard = [
        {
            titulo: "Implementacao Zabbix",
            descricao: "Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do ",
            data: "10 Mai. 2024 - 10 Out. 2024",
            status: "Concluido",
            key: "abm-01"
        },
        {
            titulo: "Implementacao Grafana",
            descricao: "Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do ",
            data: "10 Abr. 2024 - 10 Out. 2024",
            status: "Pendente",
            key: "abm-02"
        },
        {
            titulo: "Governança",
            descricao: "Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do projeto Descrição do ",
            data: "10 Jul. 2024 - 10 Out. 2024",
            status: "Em andamento",
            key: "abm-03"
        }
    ]

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const filteredData = dadosCard.filter(({ titulo, status, data }) => {
        const matchesSearchTerm = titulo.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesSelectedTag = selectedStatus === '' || status === selectedStatus || selectedStatus === "Todos";
        return matchesSearchTerm && matchesSelectedTag;
    });


    return (
        <>
            <ContainerFilter theme={theme}>
                <BoxFilter>
                    <Select
                        label="Status"
                        padrao="Todos"
                        items={["Todos", "Concluido", "Pendente", "Em andamento", "Cancelado", "Validação"]}
                        onSelect={(status) => setSelectedStatus(status)}
                    />
                </BoxFilter>
            </ContainerFilter>
            <Container theme={theme}>
                {
                    filteredData.map(({ titulo, data, descricao, status, key }) => (
                        <CardProjeto
                            chave={key}
                            titulo={titulo}
                            data={data}
                            descricao={descricao}
                            status={status as "Concluido" | "Pendente" | "Em andamento" | "Cancelado" | "Validação"}
                        />
                    ))
                }
            </Container>
        </>
    );
};


const PageConsultoria = () => {
    const location = useLocation();
    const hasQueryParams = new URLSearchParams(location.search).toString() !== '';
    const projeto = new URLSearchParams(location.search).toString().split('&').toString().split(',')[0].split('=')[1]
    const step = new URLSearchParams(location.search).toString().split('&').toString().split(',')[1]?.split('=')[1]

    return (
        <Page
            // conteudo={({open}) => !hasQueryParams ? <Conteudo /> : <ProjetoTimeline projeto={projeto} step={step} />}
            conteudo={({open}) => <Forbbiden />}
        />
    )
}

export default PageConsultoria;