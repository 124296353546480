import React, { useState } from 'react';
import Input from '../../components/Input';
import Botao from '../../components/Botao';
import styled, { css, keyframes } from 'styled-components';
import { style } from '../../styles';
import { ReactComponent as LogoImage } from '../../assets/images/logo.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToolbarButton } from '@grafana/ui';
import useToast from '../../state/hooks/useToast';
import { ReactComponent as Loading } from '../../assets/images/load.svg';
import { handleSuportPortal } from '../../utils';

const Container = styled.div`
  width: 500px;
  height: 350px;
  border-radius: .5rem;
  background: rgba( 255, 255, 255, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 13.5px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 1rem;

  @media screen and (max-width: ${style.screens.large}) {
    width: 35vw;
    height: 85vh;
  }

  @media screen and (max-width: ${style.screens.medium}) {
    width: 85vw;
    height: 85vh;
  }
`

const Forms = styled.form`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(Loading) <{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && css`
    animation: ${spin} 1s linear infinite;
  `}
`;

const FormsLogin = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  const { addToast } = useToast();

  const entrar = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoad(true);
    e.preventDefault();

    try {
      const request = await axios.post(`https://areacliente-bff-eastus.azurewebsites.net/api/login?code=QOjV9I986-NHF3aF8dUoh29o-aOLwR3ZTfPJedl6arDbAzFuscmtyw%3D%3D`, { email, senha }, { withCredentials: true });
      if (request.status === 200) {
        localStorage.setItem('email', email);
        navigate('/home');
      } else {
        addToast('Login e/ou senha inválidos', 'critical');
        setLoad(false);
      }
    } catch (err) {
      addToast('Login e/ou senha inválidos', 'critical');
      setLoad(false);
    }
  }

  return (
    <Container>
      <div style={{ width: '100%', height: '40%', alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
        <LogoImage width={130} height={130} />
        <p style={{ margin: '0px', fontFamily: style.font.family.medium }}>Portal de Clientes</p>
      </div>
      <Forms>
        <Input
          label='Username'
          placeholder='exemplo@email.com'
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          label='Password'
          placeholder='*********'
          type='password'
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
      </Forms>
      <Botao
        label='Entrar'
        variant='secondary'
        width='50%'
        onClick={entrar}
        disabled={load}
        icon={load ? <RotatingIcon width={18} height={18} isLoading={load} /> : undefined}
      />
    </Container>
  );
}

export default FormsLogin;
