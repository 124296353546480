import React from 'react';
import PageLogin from './pages/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageHome from './pages/Home';
import useInitializeTheme from './state/hooks/useInitializeTheme';
import PageDashboardCommandCenter from './pages/CommandCenter/Dashboard';
import PageAlertCommandCenter from './pages/CommandCenter/Alertas';
import PageChatSensebot from './pages/Sensebot';
import PageDocCommandCenter from './pages/CommandCenter/Documentacao';
import PageDocSensebot from './pages/Sensebot/Documentacao';
import PageRad from './pages/Rad';
import PageNexus from './pages/Nexus';
import PageConsultoria from './pages/Consultoria/Projetos';
import PagePerfil from './pages/Perfil';
import PageIncidentes from './pages/CommandCenter/Incidentes';
import { getTheme, ThemeContext } from '@grafana/ui';
import useTheme from './state/hooks/useTheme';
import Page from './pages';
import Forbbiden from './pages/Error/forbbiden';
import PageConsultoriaIncidentes from './pages/Consultoria/Incidentes';

function App() {
  useInitializeTheme();
  const { theme } = useTheme();
  const grafanaTheme = getTheme(theme === 'light' ? 'light' : 'dark');

  return (
    <BrowserRouter>
      <Routes>
        <Route Component={PageLogin} path='/' />
        <Route Component={PageHome} path='/home' />

        <Route Component={PageDashboardCommandCenter} path='/cc/dashboard' />
        <Route Component={PageDashboardCommandCenter} path='/cc/dashboard/:empresa' />
        <Route Component={PageDashboardCommandCenter} path='/cc/dashboard/:empresa/:dashboard' />

        <Route Component={PageAlertCommandCenter} path='/cc/alert' />
        <Route Component={PageAlertCommandCenter} path='/cc/alert/:empresa' />

        <Route Component={PageDocCommandCenter} path='/cc/document' />
        <Route Component={PageDocCommandCenter} path='/cc/document/:empresa' />
        <Route Component={PageDocCommandCenter} path='/cc/document/:empresa/:documento' />

        <Route Component={PageIncidentes} path='/cc/incident' />
        <Route Component={PageIncidentes} path='/cc/incident/:empresa' />
        <Route Component={PageIncidentes} path='/cc/incident/:empresa/:dashboard' />

        <Route Component={PageChatSensebot} path='/sensebot/chat' />

        <Route Component={PageDocSensebot} path='/sensebot/document' />
        <Route Component={PageDocSensebot} path='/sensebot/document/:empresa' />
        <Route Component={PageDocSensebot} path='/sensebot/document/:empresa/:documento' />

        <Route Component={PageRad} path='/rad' />
        <Route Component={PageRad} path='/rad/:empresa' />
        <Route Component={PageRad} path='/rad/:empresa/:dashboard' />

        <Route Component={PageNexus} path='/nexus' />
        <Route Component={PageNexus} path='/nexus/:empresa' />
        <Route Component={PageNexus} path='/nexus/:empresa/:dashboard' />

        <Route Component={PageConsultoria} path='/consultoria/projetos' />
        <Route Component={PageConsultoriaIncidentes} path='/consultoria/incident' />

        <Route Component={PagePerfil} path='/perfil' />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
