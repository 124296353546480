const handleSuportPortal = () => {
    const supportURL = "https://cafstecnologia.atlassian.net/servicedesk/customer/portal/15";
    window.open(supportURL, "_blank");
};

const handleContato = () => {
    const supportURL = "https://cafs.com.br/contato";
    window.open(supportURL, "_blank");
};

export { handleSuportPortal, handleContato }