import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import useTheme from "../../state/hooks/useTheme";
import useStateSubMenu from "../../state/hooks/useStateSubMenu";
import { ReactComponent as Arrow } from '../../assets/images/arrow_select.svg'
import Input from "../Input";
import Botao from "../Botao";

const Menu = styled.div<{ theme: TTheme, openMenu: boolean, openMenuPrincipal: boolean, color: string }>`
    width: ${props => props.openMenu ? '250px' : '0px'};
    height: calc(100vh - 105px);
    position: fixed;
    left: ${props => props.openMenuPrincipal ? '240px' : '70px'};
    top: 105px;
    background-color: ${props => props.color};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    padding: ${props => props.openMenu ? '1rem 0' : '1rem .3rem'};
    border-left: 1px solid ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    transition: all .8s;
    z-index: 100000;

    & a, & details, & #inputSearch  {
        display: ${props => props.openMenu ? 'flex' : 'none'};
        transition: all .8s;
    }
`

const Empresa = styled.details<{ theme: TTheme }>`
    width: 100%;
    margin-top: .6rem;    
    padding: .3rem;
    border-top: 1px solid ${style.colors.light["bg-primary"]};
    overflow: auto;
    scrollbar-width: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        display: none;
    }

    &[open] {
        height: auto;
        max-height: 25%;
    }

    &[open] svg {
        transform: rotate(0deg);
        transition: all .5s;
    }

    &:not([open]) svg {
        transform: rotate(180deg);
        transition: all .5s;
    }

    & summary {
        &:hover img {
          filter: opacity(1);
        }

        cursor: pointer;
        font-family: ${style.font.family.bold};
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;
        color: ${style.colors.dark["font-color"]};
    }

    & summary a {
        color: ${style.colors.dark["font-color"]};
        text-decoration: none;
    }

    & div {
        margin: .5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }
`

const ItemMenu = styled.a<{ selecionado?: boolean, theme: TTheme }>`
    width: 100%;
    font-size: 14px;
    font-family: ${style.font.family.light};
    box-sizing: border-box;
    padding: .3rem .5rem;
    border-radius: 4px;
    text-decoration: none;
    // margin: 2px 0;
    color: ${style.colors.dark["font-color"]};

    &:hover {
        cursor: pointer;
        background-color: ${props => props.selecionado === true ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.4)'};
    }
`

const Icone = styled(Arrow)`
    width: 18px;
    height: 18px;
    filter: opacity(0.5);
`

const ButtonOpen = styled.div<{ theme: TTheme, openMenu: boolean }>`
    width: 20px;
    height: 20px;
    position: relative;
    top: -5px;
    left: ${props => props.openMenu ? '125px' : '5px'};
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    z-index: 1000000;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,.5);
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme === 'light' ? style.colors.light['font-color'] : style.colors.dark['font-color']};

    & svg {
        transition: all .8s;
        transform: ${props => props.openMenu ? 'rotate(90deg)' : 'rotate(270deg)'};
    }
`

const ContainerInputSeatch = styled.div`
    width: 100%;
    height: 80px;
`

const InputSearch = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & input {
        font-size: 12px;

        &::placeholder {
            font-size: 12px;
        }
    }
`

const SubNavegacao: React.FC<{ dados: { empresa: string, dashboards: string[] }[], open: boolean, basePath: string; }> = ({ dados, open, basePath }) => {
    const { theme } = useTheme();
    const [stateSubMenu, setStateSubMenu] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const validaBasePath = (input: string) => {
        if (input.includes("cc")) {
            return style.colors.commandCenter;
        } else if (input.includes("sensebot")) {
            return style.colors.sensebot;
        } else if (input.includes("rad")) {
            return style.colors.rad;
        } else if (input.includes("nexus")) {
            return style.colors.nexus;
        } else {
            return style.colors.primary;
        }
    }

    const filterData = (data: { empresa: string, dashboards: string[] }[]) => {
        return data.filter(({ empresa, dashboards }) => {
            const matchEmpresa = empresa.toLowerCase().includes(searchTerm.toLowerCase());
            const matchDashboards = dashboards.some(dashboard =>
                dashboard.toLowerCase().includes(searchTerm.toLowerCase())
            );

            return matchEmpresa || matchDashboards;
        });
    }


    return (
        <>
            {dados.length <= 1 && dados[0]?.dashboards.length === 0 ? null : (
                <Menu openMenuPrincipal={open} openMenu={stateSubMenu} theme={theme} color={validaBasePath(basePath)}>
                    <ButtonOpen openMenu={stateSubMenu} onClick={() => setStateSubMenu(!stateSubMenu)} theme={theme}>
                        <Icone />
                    </ButtonOpen>
                    {filterData(dados).length > 1 ? filterData(dados).map(({ dashboards, empresa }) => (
                        <Empresa theme={theme} open={true} key={empresa}>
                            <summary>
                                <a href={`${basePath}/${empresa}`}>{empresa}</a>
                                <Icone />
                            </summary>
                            <div>
                                {dashboards.map((item: string) => (
                                    <ItemMenu href={`${basePath}/${empresa}/${item}`} theme={theme} selecionado={true} key={item}>
                                        {item}
                                    </ItemMenu>
                                ))}
                            </div>
                        </Empresa>
                    )) : filterData(dados).map(({ dashboards, empresa }) => (
                        <Empresa theme={theme} open={true} key={empresa}>
                            <summary></summary>
                            <div>
                                {dashboards.map((item: string) => (
                                    <ItemMenu href={`${basePath}/${empresa}/${item}`} theme={theme} selecionado={true} key={item}>
                                        {item}
                                    </ItemMenu>
                                ))}
                            </div>
                        </Empresa>
                    ))}
                </Menu>
            )}
        </>
    );
}

export default SubNavegacao;
