import styled from "styled-components";
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import useTheme from '../../state/hooks/useTheme';
import { NavLink } from "react-router-dom";
import { TagList } from '@grafana/ui';

const ContainerCardDoc = styled(NavLink)<{ theme: TTheme }>`
    width: 32%;
    height: 150px;
    background-color: ${({ theme }) => theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;
    padding: .2rem 1rem;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]}50;
    color: ${({ theme }) => theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    text-decoration: none;
    transition: all .4s;
    box-sizing: border-box;
    margin: .3rem;

    & ul {
        padding: 0px !important;
    }

    &:hover {
        box-shadow: 0px 0px 6px 0px ${({ theme }) => theme === 'light' ? 'rgba(0,0,0,0.4)' : 'rgb(0,0,0)'};
    }

    & h2 {
        width: 100%;
        font-family: ${style.font.family.light};
        font-size: 16px;
        text-align: start;
    }
`;

const ContainerTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
    height: 60%;
`;

interface ICardList {
    titulo: string;
    tags: { tag: string, value: string }[];
    to: string;
}

const CardList: React.FC<ICardList> = ({ titulo, tags, to }) => {
    const { theme } = useTheme();
    const valueTags = tags.map(({ tag, value }) => value);

    return (
        <ContainerCardDoc to={to} theme={theme}>
            <h2>{titulo}</h2>
            <ContainerTag>
                <TagList tags={valueTags} />
            </ContainerTag>
        </ContainerCardDoc>
    );
};

export default CardList;
