import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { ReactComponent as Dark } from '../../assets/images/dark.svg';
import { ReactComponent as Light } from '../../assets/images/light.svg';
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";

const SwitchContainer = styled.div`
  width: 40px;
`;

const HiddenSwitch = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
`;

const Label = styled.label<{ theme: TTheme }>`
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
  padding: 1px;
  width: 40px;
  height: 20px;
  background-color: #dddddd;
  border-radius: 20px;
  transition: background 0.4s;

  &::before,
  &::after {
    display: block;
    position: absolute;
    content: "";
  }

  &::before {
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background-image: ${props => props.theme === 'light' ? `url(${Light})` : `url(${Dark})`};
    background-size: cover;
    border-radius: 20px;
    transition: background 0.4s;
  }

  &::after {
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 16px;
    background-color: #dddddd;
    border-radius: 16px;
    transition: margin 0.4s, background 0.4s;
  }
`;

const Switch = styled(HiddenSwitch)<{ theme: TTheme }>`
  & + ${Label} {
    &::before,
    &::after {
      top: 1px;
      left: 1px;
      bottom: 1px;
      right: 1px;
      background-color: ${props => props.theme === 'light' ? style.colors.light['bg-secondary'] : style.colors.dark['bg-secondary']};
      border-radius: 20px;
      transition: background 0.4s;
    }

    &::after {
      top: 3px;
      left: 3px;
      bottom: 2px;
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 16px;
      transition: margin 0.4s, background 0.4s;
    }
  }

  &:checked + ${Label} {
    &::after {
      margin-left: 20px;
    }
  }
`;

const SwitchTheme = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <SwitchContainer>
            <Switch checked={theme !== 'light'} theme={theme} onChange={toggleTheme} id="switch-theme" className="switch switch--flat" />
            <Label htmlFor="switch-theme" theme={theme}>
                {
                    theme === 'dark' ?
                    <Dark style={{ top: '3px', right: '3px', bottom: '2px', position: 'absolute', zIndex: '1000' }} width={16} height={16}/> :
                    <Light style={{ top: '3px', left: '3px', bottom: '2px', position: 'absolute', zIndex: '1000' }} width={16} height={16}/>
                }
            </Label>
        </SwitchContainer>
    )
}

export default SwitchTheme;
