import React, { useEffect, useState } from "react";
import Page from "../../pages";
import styled from "styled-components";
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import useTheme from '../../state/hooks/useTheme';
import { useParams } from "react-router-dom";
import SubNavegacao from "../../components/SubNavegacao";
import Items from "../../containers/Items";
import '../../styles/docs.css';
import instancia from "../../instancia";
import Loading from "../../components/Loading";
import Forbbiden from "../../pages/Error/forbbiden";
import ServerError from "../../pages/Error/serverError";

const ContainerMark = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: 95%;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    border-radius: .5rem;
    padding: 1rem 3rem;
    box-sizing: border-box;
    margin-top: 1rem;
`;

const ConteudoDoc = ({ content }: { content: string }) => {
    const { theme } = useTheme();
    return (
        <>
            {
                content ?
                    <>
                        <ContainerMark theme={theme}>
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </ContainerMark>
                    </> :
                    <Loading />
            }
        </>
    );
};

const LayoutDocumentacao: React.FC<{ basePath: string, endpointData: string }> = ({ basePath, endpointData }) => {
    const { empresa, documento } = useParams<{ empresa: string, documento?: string }>();
    const [subNav, setSubNav] = useState<{ empresa: string; dashboards: string[]; }[]>([]);
    const [dadosCard, setDadosCard] = useState<any[]>([]);
    const [docContent, setDocContent] = useState<string>('');
    const [empresaValue, setEmpresaValue] = useState('');
    const [error, setError] = useState<number>();

    useEffect(() => {
        instancia
            .get(`${endpointData}/documents?code=_VFGT9joepL3KeKfxlRDVJF-Z8JXNQznUxP0CiIfXHlYAzFuV7VlsQ%3D%3D`, { withCredentials: true })
            .then(res => {
                const subnavegacao = res.data?.map((value: any) => {
                    return {
                        empresa: value.empresa,
                        dashboards: value.docs.map((e: any) => e.title)
                    }
                });
                setSubNav(subnavegacao);
                setEmpresaValue(subnavegacao[0]);

                if (empresa) {
                    const dados = res.data.find((value: any) => value.empresa === empresa);
                    const formatDados = dados?.docs.map((value: any) => {
                        return {
                            id: value.id,
                            title: value.title,
                            tags: value.tags.map((tag: string) => {
                                return { tag: dados?.empresa, value: tag }
                            })
                        }
                    })
                    setDadosCard(formatDados || []);
                } else {
                    const formatDados = res.data[0]?.docs.map((value: any) => {
                        return {
                            id: value.id,
                            title: value.title,
                            tags: value.tags.map((tag: string) => {
                                return { tag: res.data[0]?.empresa, value: tag }
                            })
                        }
                    })
                    setDadosCard(formatDados || []);
                }
            })
            .catch(err => {
                const status = err.response?.status;
                setError(status || 0)
            })
    }, [empresa]);

    useEffect(() => {
        if (documento) {
            setDocContent('');
            const docData = dadosCard.find(doc => doc.title === documento);
            if (docData) {
                instancia
                    .post(`${endpointData}/doc-page?code=_VFGT9joepL3KeKfxlRDVJF-Z8JXNQznUxP0CiIfXHlYAzFuV7VlsQ%3D%3D`, { id: docData.id, title: docData.title }, { withCredentials: true })
                    .then(res => {
                        setDocContent(res.data.content);
                    })
                    .catch(err => console.log(err.message));
            }
        }
    }, [documento, dadosCard]);

    return (
        <Page
            conteudo={({ open }) =>
                error ?
                    error === 403 ? <Forbbiden /> : <ServerError /> :
                    documento ?
                        <>
                            <SubNavegacao basePath={basePath} open={open || false} dados={subNav} />
                            <ConteudoDoc content={docContent} />
                        </>
                        :
                        <>
                            <SubNavegacao basePath={basePath} open={open || false} dados={subNav} />
                            <Items empresaValue={empresaValue} dadosCard={dadosCard} />
                        </>
            }
        />
    );
};

export default LayoutDocumentacao;
