import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RecoilRoot } from "recoil";
import ToastManager from './containers/ToastManager';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <RecoilRoot>
      <App />
      <ToastManager />
    </RecoilRoot>
  // <React.StrictMode>
  // </React.StrictMode>
);
