import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TStatus } from "../../types/types";
import { style } from "../../styles";
import { lighten } from 'polished';
import { ReactComponent as Info } from '../../assets/images/info.svg'
import { ReactComponent as Warning } from '../../assets/images/warning.svg'
import { ReactComponent as Critical } from '../../assets/images/critical.svg'
import { ReactComponent as Success } from '../../assets/images/success.svg'

const Container = styled.div<{ severidade: TStatus }>`
    width: auto;
    min-height: 40px;
    height: auto;
    max-height: 300px;
    box-sizing: border-box;
    padding: .5rem 1rem;
    border-radius: 4px;
    background-color: ${props => style.colors[props.severidade]};
    border: 1px solid ${props => lighten(0.4, style.colors[props.severidade])};
    box-shadow: 0px 0px 5px 0px ${props => style.colors[props.severidade]};
    color: ${props => props.severidade === 'warning' ? style.colors.light['font-color'] : style.colors.dark["font-color"]};
`;

const Icone = styled.div<{ severidade: TStatus }>`
    color: ${props => lighten(0.4, style.colors[props.severidade])};
    width: 20px;
    height: 20px;
`;

const Titulo = styled.h2`
    font-size: 14px;
    font-family: ${style.font.family.semiBold};
    margin: 0;
    margin-left: 1rem;
`;

const ContianerContent = styled.div`
    width: 100%;
    min-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex-grow: 1;
`;

const Texto = styled.p`
    font-family: ${style.font.family.light};
    font-size: 14px;
    width: calc(100% - (1rem + 20px));
    margin-left: calc(1rem + 20px);
`;

const Alert = ({ severidade, titulo, texto }: { severidade: TStatus, titulo: string, texto?: string }) => {
    var icon;

    switch (severidade) {
        case 'success':
            icon = <Success />
            break;
        case 'info':
            icon = <Info />
            break;
        case 'critical':
            icon = <Critical />
            break
        case 'warning':
            icon = <Warning />
            break
    }

    return (
        <Container severidade={severidade}>
            <ContianerContent>
                <Icone severidade={severidade}>
                    {icon}
                </Icone>
                <Titulo>{titulo}</Titulo>
            </ContianerContent>
            {
                texto ?
                    <Texto>{texto}</Texto> : null
            }
        </Container>
    )
}

export default Alert;