import { useSetRecoilState } from 'recoil';
import { toastState } from '../atom';
import { TStatus } from '../../types/types';

const useToast = () => {
    const setToasts = useSetRecoilState(toastState);

    const addToast = (message: string, severidade: TStatus) => {
        setToasts((prevToasts) => {
            const newToasts = [...prevToasts, { message, severidade }];
            return newToasts.length > 3 ? newToasts.slice(1) : newToasts;
        });
    };

    const removeToast = (index: number) => {
        setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
    };

    return { addToast, removeToast };
};

export default useToast;
