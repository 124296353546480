import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles/index";
import { TTheme } from "../../types/types";
import useTheme from '../../state/hooks/useTheme';
import CardList from "../../components/CardList";
import SelectMulti from "../../components/SelectMulti";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { ReactComponent as IconMore } from "../../assets/images/more.svg";
import { ReactComponent as IconSkip } from '../../assets/images/arrow_select.svg'
import Input from "../../components/Input";

const Container = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    overflow: auto;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    border-radius: .5rem;
    padding: 1rem 0;
    box-sizing: border-box;
`;

const ContainerFilter = styled.div<{ theme: TTheme }>`
    width: 670px;
    height: 38px;
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    box-sizing: border-box;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;

const Button = styled.button<{ disabled?: boolean }>`
    width: 32px;
    height: 24px;
    background-color: ${({ disabled }) => (disabled ? '#ccc' : style.colors.secondary)};
    color: white;
    border: none;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    &:hover {
        background-color: ${({ disabled }) => (disabled ? '#ccc' : style.colors.primary)};
    }
`;

const ButtonNumber = styled.button<{ active?: boolean }>`
    width: 24px;
    height: 24px;
    background-color: ${({ active, disabled }) => (active ? style.colors.primary : disabled ? 'transparent' : style.colors.secondary)};
    color: ${({ disabled }) => disabled ? style.colors.secondary : 'white'};
    border: none;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 0;
    cursor:  ${({ disabled }) => disabled ? 'default' : 'pointer'};
    &:hover {
        background-color: ${({ active, disabled }) => (active ? style.colors.primary : disabled ? 'transparent' : style.colors.primary)};
    }
`;

interface IItems {
    dadosCard: {
        title: string,
        id: string,
        tags: {
            tag: string,
            value: string,
        }[]
    }[],
    empresaValue?: any
}

const Items: React.FC<IItems> = ({ dadosCard, empresaValue }) => {
    const { theme } = useTheme();
    const { empresa } = useParams();

    // Estados para filtro e página
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTag, setSelectedTag] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    const tags = Array.from(
        new Set(
            dadosCard?.flatMap(({ tags }) => tags?.map(({ value }) => value))
        )
    );

    // Efeito para resetar a página quando o filtro ou pesquisa mudar
    useEffect(() => {
        setCurrentPage(1);  // Reseta para a primeira página ao mudar filtro
    }, [searchTerm, selectedTag]);  // Monitora mudanças no searchTerm e selectedTag

    // Função que filtra os dados com base na busca e nos filtros aplicados
    const filteredData = dadosCard.filter(({ title, tags }) => {
        const matchesSearchTerm = title?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesSelectedTag = selectedTag.length === 0 || tags?.some(tag => selectedTag.includes(tag.value));
        return matchesSearchTerm && matchesSelectedTag;
    });

    const paginate = (data: typeof filteredData, page: number, itemsPerPage: number) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    const paginatedData = paginate(filteredData, currentPage, itemsPerPage);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 3;

        const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
        const endPage = Math.min(totalPages, currentPage + Math.floor(maxPageNumbersToShow / 2));

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <ButtonNumber key={i} active={i === currentPage} onClick={() => handlePageClick(i)}>
                    {i}
                </ButtonNumber>
            );
        }

        return (
            <>
                {startPage > 1 && (
                    <>
                        <ButtonNumber onClick={() => handlePageClick(1)}>1</ButtonNumber>
                        {startPage > 2 && <ButtonNumber disabled={true}><IconMore width={18} height='auto' /></ButtonNumber>}
                    </>
                )}
                {pageNumbers}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <ButtonNumber disabled={true}><IconMore width={18} height='auto' /></ButtonNumber>}
                        <ButtonNumber onClick={() => handlePageClick(totalPages)}>{totalPages}</ButtonNumber>
                    </>
                )}
            </>
        );
    };
    
    return (
        <>
        <p style={{ color: style.colors[theme]["font-color"], marginBottom: '1rem', marginLeft: '1rem' }}>{empresa ? empresa : empresaValue.empresa}</p>
            <ContainerFilter theme={theme}>
                <Input
                    placeholder="Procurar dashboard"
                    type="text"
                    label="Pesquisar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <SelectMulti
                    label="Filtrar por tag"
                    items={tags}
                    onSelect={(tag) => setSelectedTag(tag)}
                />
            </ContainerFilter>
            <Container theme={theme}>
                {
                    paginatedData.length !== 0 ?
                        paginatedData.map(({ tags, title }, index) => (
                            <CardList
                                key={index}
                                to={`${empresa ? encodeURIComponent(title) : `${empresaValue.empresa}/${encodeURIComponent(title)}`}`}
                                titulo={title}
                                tags={tags ?? []}
                            />
                        )) :
                        <Loading />
                }
            </Container>
            {filteredData.length > itemsPerPage && (
                <PaginationContainer>
                    <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                        <IconSkip width={14} height={14} style={{ transform: 'rotate(90deg)' }} />
                    </Button>
                    {renderPageNumbers()}
                    <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        <IconSkip width={14} height={14} style={{ transform: 'rotate(270deg)' }} />
                    </Button>
                </PaginationContainer>
            )}
        </>
    );
};

export default Items;
