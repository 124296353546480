import React from "react";
import styled from "styled-components";
import { ReactComponent as Cafs } from '../../assets/images/cafs.svg';
import { style } from "../../styles";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .spinner {
        border: 6px solid #fff;
        border-left-color: ${style.colors.primary};
        height: 50px;
        width: 50px;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`

const Loading = () => {
    return(
        <Container>
            <div className="spinner"></div>
        </Container>
    )
}

export default Loading;