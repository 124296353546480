import React, { useState } from "react";
import styled from "styled-components";
import useTheme from "../../state/hooks/useTheme";
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import { To } from "react-router-dom";
import { Link } from "react-router-dom";

const Card = styled(Link)<{ theme: TTheme }>`
    width: 200px;
    height: 200px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    margin: 1.5rem;
    margin-left: 0px;
    cursor: pointer;
    text-decoration: none;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
`

const CardText = styled.p<{ theme: TTheme }>`
    font-size: ${style.font.size.p};
    font-family: ${style.font.family.regular};
    text-align: center;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
`

interface ICardComponent {
    imagem?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    text: string
    theme?: TTheme
    link: To
}

const CardComponent = ({ imagem: CardImage, text, theme: tema, link }: ICardComponent) => {
    const { theme } = useTheme();

    return (
        <Card to={link} theme={theme}>
            {
                CardImage ?
                    <CardImage width={50} height={50} /> : null
            }
            <CardText theme={theme}>{text}</CardText>
        </Card>
    )
}

export default CardComponent;