import React, { useEffect, useRef, useState } from "react";
import { getTheme, InlineField } from '@grafana/ui';
import useTheme from '../../state/hooks/useTheme';
import styled from "styled-components";
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import { ReactComponent as Arrow } from '../../assets/images/arrow_select.svg'

interface ISelect {
    label: string;
    items: string[];
    padrao?: string;
    onSelect: (item: string) => void;
    fullWidth?: boolean;
}

const Container = styled.div<{ theme: any }>`
    width: auto;
    height: auto;

    & label {
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
        font-family: ${style.font.family.light};
    }
`

const ContainerSelect = styled.div<{ theme: TTheme, fullWidth: boolean, themeGrafana: any, open: boolean }>`
    width: 180px;
    height: 32px;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 .5rem 0 1rem;
    font-family: ${style.font.family.light};
    position: relative;
    border: 1px solid ${({ themeGrafana }) => themeGrafana.colors.border2};
    color: ${({ themeGrafana }) => themeGrafana.colors.text};
    box-shadow: ${({ open, themeGrafana }) => open ? `0px 0px 0px 4px ${themeGrafana.colors.formInputBorderActive}` : ''};
    outline: ${({ open }) => open ? `2px solid black` : 'none'};
    transition: all .3s;
    
    & p {
        font-size: 14px;
    }

    &:hover {
        border: 1px solid ${({ themeGrafana }) => themeGrafana.colors.border3};
    }

    &:hover > div {
        color: ${({ themeGrafana }) => themeGrafana.colors.border3}
    }

`
const IconSelect = styled.div<{ theme: any, open: boolean }>`
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.border2};
    transform: ${({ open }) => open ? "rotate(180deg)" : ""};
    transition: all .6s;
`

const MenuSelect = styled.div<{ theme: any, open: boolean, fullWidth: boolean }>`
    width: ${props => props.fullWidth ? '100%' : '180px'};
    min-height: 5rem;
    max-height: 15rem;
    height: auto;
    left: 0px;
    top: calc(100% + 10px);
    box-sizing: border-box;
    position: absolute;
    border-radius: 2px;
    padding: .5rem 0;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    background-color: ${({theme}) => theme.name === 'Light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    box-shadow: 0px 3px 15px 1px ${({ theme }) => theme.name === 'Light' ? 'rgba(0,0,0,0.4)' : 'rgb(0,0,0)'};
    overflow: auto;
    scrollbar-width: none;
    border: 1px solid ${({ theme }) => theme.colors.border2};

    &::-webkit-scrollbar {
        display: none;
    }
        
    svg {
        transform: rotate(180deg);
    }
`

const ItemMenuSelect = styled.div<{ theme: any }>`
    font-family: ${style.font.family.light};
    color:  ${({ theme }) => theme.colors.text};
    text-align: center;
    width: 100%;
    height: 32px;
    padding: .5rem 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin: .2rem 0;
    font-size: 16px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.bg3};
    }
`


const CustomSelect: React.FC<ISelect> = ({ label, items, padrao, onSelect, fullWidth }) => {
    const { theme } = useTheme();
    const themeGrafana = getTheme(theme);

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(padrao ?? "");
    const menuRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    return (
        <Container ref={menuRef} theme={themeGrafana}>
            <InlineField
                label={label}
            >
                <ContainerSelect open={open} themeGrafana={themeGrafana} onClick={() => setOpen(!open)} theme={theme} fullWidth={fullWidth ?? false}>
                    <p>{value}</p>
                    <IconSelect theme={themeGrafana} open={open}>
                        <Arrow width={16} height={16} />
                    </IconSelect>
                    {
                        open && items.length > 0 ?
                            <MenuSelect fullWidth={fullWidth ?? false} theme={themeGrafana} open={open}>
                                {
                                    items.map((value, index) => (
                                        <ItemMenuSelect theme={themeGrafana} key={index} onClick={() => {
                                            setValue(value)
                                            onSelect(value)
                                        }}>{value}</ItemMenuSelect>
                                    ))
                                }
                            </MenuSelect> : null
                    }
                </ContainerSelect>
            </InlineField>
        </Container>
    );
};

export default CustomSelect;
