import { atom } from 'recoil';
import { TTheme } from '../types/types';
import { IToast } from '../types/interfaces';

export const theme = atom<TTheme>({
    key: 'theme',
    default: 'light',
});

export const fixedMenu = atom<boolean>({
    key: 'fixedMenu',
    default: true,
});


export const stateSubMenu = atom<boolean>({
    key: 'stateSubMenu',
    default: false,
});

export const toastState = atom<IToast[]>({
    key: 'toastState',
    default: [],
});