import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { theme } from '../atom';
import { TTheme } from '../../types/types';

const useInitializeTheme = () => {
  const setTheme = useSetRecoilState(theme);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as TTheme;
    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      setTheme(systemTheme);
      localStorage.setItem('theme', systemTheme);
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      const newTheme = mediaQuery.matches ? 'dark' : 'light';
      setTheme(newTheme);
      localStorage.setItem('theme', newTheme);
    };

    mediaQuery.addEventListener('change', handleChange);
    
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [setTheme]);
};

export default useInitializeTheme;
