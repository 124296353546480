import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../pages";
import Dashboard from "../../containers/Dashboard";
import Items from "../../containers/Items";
import SubNavegacao from "../../components/SubNavegacao";
import instancia from "../../instancia";
import Forbbiden from "../../pages/Error/forbbiden";
import ServerError from "../../pages/Error/serverError";

const LayoutDashboard: React.FC<{ endpointDados: string, basePath: string }> = ({ endpointDados, basePath }) => {
    const { empresa, dashboard } = useParams<{ empresa: string, dashboard?: string }>();
    const [subNav, setSubNav] = useState<{ empresa: string; dashboards: string[]; }[]>([]);
    const [empresaValue, setEmpresaValue] = useState('');

    const [dadosCard, setDadosCard] = useState<any[]>([]);
    const [token, setToken] = useState<string>();
    const [error, setError] = useState<number>();

    useEffect(() => {
        instancia
            .get(`${endpointDados}?code=_VFGT9joepL3KeKfxlRDVJF-Z8JXNQznUxP0CiIfXHlYAzFuV7VlsQ%3D%3D`, { withCredentials: true })
            .then(res => {
                const subnavegacao = res.data?.map((value: any) => {
                    return {
                        empresa: value.empresa,
                        dashboards: value.dashboards.map((e: any) => e.title)
                    }
                });
                setSubNav(subnavegacao);
                setEmpresaValue(subnavegacao[0]);

                if (empresa) {
                    const dados = res.data.find((value: any) => value.empresa === empresa);
                    const formatDados = dados?.dashboards.map((value: any) => {
                        return {
                            title: value.title, url: value.url, tags: value.tags.map((v: string) => {
                                return { value: v.split(":")[1], tag: v.split(":")[2] }
                            })
                        }
                    })

                    setDadosCard(formatDados || []);
                    setToken(dados.tokenGrafana);
                } else {
                    const formatDados = res.data[0]?.dashboards.map((value: any) => {
                        return {
                            title: value.title, url: value.url, tags: value.tags.map((v: string) => {
                                return { value: v.split(":")[1], tag: v.split(":")[2] }
                            })
                        }
                    })

                    setDadosCard(formatDados || []);
                    setToken(res.data[0].tokenGrafana);
                }
            })
            .catch(err => {
                const status = err.response?.status;
                setError(status || 0)
            })
    }, [empresa]);

    return (
        <Page
            conteudo={({ open }) =>
                error ?
                    error === 403 ? <Forbbiden /> : <ServerError /> :
                    dashboard ?
                        <>
                            <SubNavegacao basePath={basePath} open={open || false} dados={subNav} />
                            <Dashboard filterData={endpointDados !== '/rad'} titulo={dashboard} dataDashboard={dadosCard} token={token || ""} />
                        </> :
                        <>
                            <SubNavegacao basePath={basePath} open={open || false} dados={subNav} />
                            <Items empresaValue={empresaValue} dadosCard={dadosCard} />
                        </>
            }
        />
    );
};

export default LayoutDashboard;
