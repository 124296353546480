import styled from "styled-components";
import { TTheme } from "../../types/types";
import { style } from "../../styles";

const BotaoIcone = styled.div<{ theme: TTheme, selecionado: boolean }>`
    width: 44px;
    height: 44px;
    border-radius: 4px;
    background-color: ${props => props.selecionado ? props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"] : ""};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};

    &:hover {
        background-color: ${props => props.selecionado ? "" : `${style.colors.secondary}70`};
    }
`

export default BotaoIcone;
