import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { ReactComponent as Menu } from '../../assets/images/menu.svg';
import { TTheme } from "../../types/types";
import useTheme from '../../state/hooks/useTheme';
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoCloseSvg } from '../../assets/images/cafs.svg';
import Config from "../../components/Config";
import MenuAvatar from "../../components/MenuAvatar";

interface IContainer {
    open: boolean
    theme: TTheme
}

const Container = styled.div<IContainer>`
    width: 100vw;
    height: 64px;
    top: 0;
    position: absolute;
    left: 0;
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    box-sizing: border-box;
    padding-right: 70px;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};

`

const IconMenu = styled.div<{ theme: TTheme }>`
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all .3s linear;
    cursor: pointer;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    
    &:hover {
        background-color: ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    }

`

const ContainerFuncs = styled.div`
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const ContainerHome = styled(NavLink) <{ theme: TTheme }>`
    width: 65%;
    height: 45px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 .8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    transition: all .3s linear;
    
    &:hover {
        background-color: ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    }


    & p {
        font-family: ${style.font.family.medium};
        font-size: 16px;
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 300px;
`

interface ITopbar {
    onClickMenu: React.MouseEventHandler<HTMLDivElement>
    open: boolean
}

const Topbar = ({ onClickMenu, open }: ITopbar) => {
    const { theme } = useTheme();

    return (
        <Container theme={theme} open={open}>
            <Box>
                <IconMenu theme={theme} onClick={onClickMenu}>
                    <Menu />
                </IconMenu>
                <ContainerHome to='/home' theme={theme}>
                    <LogoCloseSvg width='25px' />
                    <p>CAFS Tecnologia</p>
                </ContainerHome>
            </Box>
            <ContainerFuncs>
                <Config />
                <MenuAvatar />
            </ContainerFuncs>
        </Container>
    )
}

export default Topbar;