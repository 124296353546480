import React from "react";
import LayoutDocumentacao from "../../../layouts/Documents";

const PageDocSensebot: React.FC = () => {
    return (
        <LayoutDocumentacao
            basePath="/sensebot/document"
            endpointData="sensebot"
        />
    );
};

export default PageDocSensebot;
