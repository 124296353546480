import React, { useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import { TStatus, TTheme } from "../../types/types";
import useTheme from "../../state/hooks/useTheme";
import { ReactComponent as IconClose } from '../../assets/images/close.svg';
import { style } from "../../styles";
import Alert from "../Alert";

const fadeIn = keyframes`
    0% {
        opacity: 0;
        right: -300px;
    }
    100% {
        opacity: 1;
        right: 0px;
    }
`;

const Container = styled.div<{ theme: TTheme }>`
    width: 300px;
    height: 50px;
    position: relative;
    box-shadow: 0px 3px 6px 2px ${props => props.theme === 'light' ? 'rgba(0,0,0,0.4)' : 'black'};
    animation: ${fadeIn} 0.5s ease-in-out;
    background-color: white;
`;

const ContainerBotaoIcone = styled.div`
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 2px;
    top: 4px;
    right: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    color: white;

    &:hover {
        background-color: ${`${style.colors.secondary}70`};
    }
`;

const Toast = ({ message, severidade, onClose }: { message: string, severidade: TStatus, onClose: () => void }) => {
    const { theme } = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <Container theme={theme}>
            <ContainerBotaoIcone onClick={onClose}>
                <IconClose width={16} height={16} />
            </ContainerBotaoIcone>
            <Alert severidade={severidade} titulo={message} />
        </Container>
    );
};

export default Toast;
