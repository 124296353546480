import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Page from "../..";
import styled, { keyframes } from "styled-components";
import { style } from "../../../styles";
import { TSeverity, TStatus, TTheme } from "../../../types/types";
import useTheme from '../../../state/hooks/useTheme';
import axios from "axios";
import SubNavegacao from "../../../components/SubNavegacao";
import instancia from "../../../instancia";
import Loading from "../../../components/Loading";
import Forbbiden from "../../Error/forbbiden";
import ServerError from "../../Error/serverError";


const skeletonAnimation = keyframes`
    0% {
        background-position: -500px 0;
    }
    50% {
        background-position: 200px 0;
    }
`;

const CardLoad = styled.div<{ theme: TTheme }>`
    width: 16%;
    height: 100%;
    border-radius: .5rem;
    background-image: ${props => props.theme === 'light'
        ? `linear-gradient(90deg, ${style.colors.light["bg-secondary"]} 25%, #e0e0e0 50%, ${style.colors.light["bg-secondary"]} 75%)`
        : `linear-gradient(90deg, ${style.colors.dark["bg-secondary"]} 25%, #2a2d32 50%, ${style.colors.dark["bg-secondary"]} 75%)`};
    background-size: 400% 100%;
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    animation: ${skeletonAnimation} 3s ease-in-out infinite;
`;

const Container = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    overflow: auto;
    overflow-x: hidden;
    margin-top: 1rem;
    border-radius: .5rem;
    padding: 1rem;
    box-sizing: border-box;
`;

const Conteudo: React.FC<{ open: boolean }> = ({ open }) => {
    const { theme } = useTheme();
    const [stat, setStat] = useState<any[]>([]);
    const [dados, setDados] = useState<{ title: string, tags: { value: string, tag: string }[], url: string } | undefined>(undefined);
    const [token, setToken] = useState<any[]>([]);

    const [subNav, setSubNav] = useState<{ empresa: string; dashboards: string[]; }[]>([]);
    const [error, setError] = useState<number>();

    const { empresa } = useParams();
    const [empresaValue, setEmpresaValue] = useState('');

    useEffect(() => {
        instancia.get('command-center/problemas?code=_VFGT9joepL3KeKfxlRDVJF-Z8JXNQznUxP0CiIfXHlYAzFuV7VlsQ%3D%3D')
            .then(res => {
                const subnavegacao = res.data?.map((value: any) => {
                    return {
                        empresa: value.empresa,
                        dashboards: []
                    }
                });

                if (subnavegacao.length > 0) {
                    setSubNav(subnavegacao);
                    setEmpresaValue(subnavegacao[0]);
                }

                if (empresa) {
                    const dados = res.data.find((value: any) => value.empresa === empresa);
                    setDados(dados.dashboards[0]);
                    setToken(dados.tokenGrafana);
                } else {
                    setDados(res.data[0].dashboards[0]);
                    setToken(res.data[0].tokenGrafana);
                }

            })
            .catch(err => {
                const status = err.response.status;
                setError(status || 0)
            })
    }, [empresa])

    return (
        <>
            {
                subNav.length !== 0 ? <SubNavegacao basePath="/cc/alert" open={open} dados={subNav} /> : null
            }
            {
                error ?
                error === 403 ? <Forbbiden /> : <ServerError /> :
                <Container theme={theme}>
                    {
                        dados !== undefined ?
                        <iframe
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                marginTop: '1rem'
                            }}
                            src={`https://grafana.cafs.com.br${dados?.url}?orgId=1&kiosk&auth_token=${token}&theme=${theme}`}
                        /> :
                        <Loading />
                    }
                </Container>
            }
        </>
    );
};

const PageAlertCommandCenter = () => {

    return (
        <Page
            conteudo={({ open }) => <Conteudo open={open || false} />}
        />
    );
};

export default PageAlertCommandCenter;
