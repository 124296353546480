import React from "react";
import LayoutDashboard from "../../../layouts/Dashboard";

const PageDashboardCommandCenter: React.FC = () => {
    return (
        <LayoutDashboard
            basePath="/cc/dashboard"
            endpointDados="/command-center/dashboards"
        />
    );
};

export default PageDashboardCommandCenter;
