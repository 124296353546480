import React from "react";
import LayoutDashboard from "../../../layouts/Dashboard";


const PageIncidentes: React.FC = () => {
    return (
        <LayoutDashboard
            basePath="/cc/incident"
            endpointDados="/command-center/incidents"
        />
    );
}

export default PageIncidentes;