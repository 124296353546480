import { useRecoilState } from 'recoil';
import { fixedMenu as fixedMenuAtom } from '../atom';

const useFixedMenu = () => {
  const [fixedMenu, setFixedMenu] = useRecoilState(fixedMenuAtom);

  const toggleFixedMenu = () => {
    const state = `${!fixedMenu}`;
    setFixedMenu(!fixedMenu);
    localStorage.setItem('fixed-menu', state);
  };

  return { fixedMenu, toggleFixedMenu };
};

export default useFixedMenu;