import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Icon } from '../../assets/images/config.svg'
import styled from "styled-components";
import { TTheme } from "../../types/types";
import { style } from "../../styles";
import useTheme from "../../state/hooks/useTheme";
import SwitchButton from "../Switch";
import SwitchTheme from "../SwitchTheme";
import { ReactComponent as IconDownload } from '../../assets/images/download.svg'
import useFixedMenu from "../../state/hooks/useFixedMenu";
import Dropdown from "../Dropdown";
import { ToolbarButton } from "@grafana/ui";
import useToast from "../../state/hooks/useToast";
import Botao from "../Botao";

const ContainerItem = styled.div<{ theme: TTheme, cursor: boolean }>`
    width: 100%;
    height: 34px;
    box-sizing: border-box;
    padding: .3125rem .625rem;
    background-color: ${props => props.theme === 'light' ? style.colors.light['bg-secondary'] : style.colors.dark["bg-secondary"]};
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.cursor ? "space-evenly" : "space-between"};
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    transition: all .3s ease;
    text-decoration: none;
    
    &:hover {
        cursor: ${props => props.cursor ? "pointer" : "default"};
        background-color: ${props => props.cursor ? props.theme === 'light' ? style.colors.light['bg-primary'] : style.colors.dark["bg-primary"] : ""};
    }
`

const Label = styled.p`
    font-size: 14px;
    font-family: ${style.font.family.light};
`

interface IItemMenu {
    label: string
    button: JSX.Element
    cursor?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const ItemMenu = ({ label, button, cursor, onClick }: IItemMenu) => {
    const { theme } = useTheme();

    return (
        <ContainerItem
            theme={theme}
            cursor={cursor ?? false}
            onClick={onClick}
        >
            <Label>{label}</Label>
            {button}
        </ContainerItem>
    );
};

const Config = () => {
    const { fixedMenu, toggleFixedMenu } = useFixedMenu();
    const [deferredPrompt, setDeferredPrompt] = useState<any | null>(null);
    const { addToast } = useToast();

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: any) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
                addToast('App instalado com sucesso!', 'success');
                setDeferredPrompt(null);
            });
        } else {
            addToast('Erro ao instalar o aplicativo', 'critical');
        }
    };

    return (
        <Dropdown icon={<Icon />}>
            <>
                <ItemMenu
                    label="Menu fixo"
                    button={<SwitchButton value={fixedMenu} onChange={toggleFixedMenu} />}
                />
                <ItemMenu
                    label="Tema"
                    button={<SwitchTheme />}
                />
                <Botao
                    disabled={false}
                    icon={<IconDownload width={16} height={16} />}
                    variant="secondary"
                    onClick={handleInstallClick}
                    label="Download App"
                    width='100%'
                />
            </>
        </Dropdown>
    )
}

export default Config;
