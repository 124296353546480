import React from "react";
import LayoutDashboard from "../../layouts/Dashboard";

const PageNexus: React.FC = () => {
    return (
        <LayoutDashboard
            basePath="/nexus"
            endpointDados="/nexus"
        />
    )
}

export default PageNexus;