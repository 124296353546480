import React, { useEffect, useState } from "react";
import { ReactComponent as IconeCommandCurrent } from '../../assets/images/cc-icon.svg';
import { ReactComponent as IconeCommandColorido } from '../../assets/images/command-colorido.svg';

import { ReactComponent as sensebotCurrent } from '../../assets/images/sensebot-current.svg';
import { ReactComponent as sensebotColorido } from '../../assets/images/sensebot-colorido.svg';

import { ReactComponent as radCurrent } from '../../assets/images/rad-current.svg';
import { ReactComponent as radColorido } from '../../assets/images/rad-colorido.svg';

import { ReactComponent as recebiveisCurrent } from '../../assets/images/recebiveis-current.svg';
import { ReactComponent as recebiveisColorido } from '../../assets/images/recebiveis-colorido.svg';

import { ReactComponent as consultoriaCurrent } from '../../assets/images/consultoria-current.svg';
import { ReactComponent as consultoriaColorido } from '../../assets/images/consultoria-colorido.svg';

import { ReactComponent as LogoSvg } from '../../assets/images/logo.svg';
import { ReactComponent as LogoCloseSvg } from '../../assets/images/cafs.svg';
import { useLocation } from 'react-router-dom';

import ItemMenu from "../../components/ItemMenu";
import styled, { css, keyframes } from "styled-components";
import { style } from "../../styles";
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconeMenu } from '../../assets/images/menu.svg';
import useFixedMenu from "../../state/hooks/useFixedMenu";

interface ContainerProps {
    open: boolean
    theme: TTheme
}

const Container = styled.div<ContainerProps>`
    width: ${props => props.open ? '240px' : '70px'};
    height: calc(100vh - 100px);
    top: 100px;
    transition: width .8s;
    padding: 1.5rem 0;
    box-sizing: border-box;
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: fixed;
    z-index: 10;
`;

const Menu = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`

const Divisor = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme === 'light' ? style.colors.dark["bg-primary"] : style.colors.light["bg-primary"]};
    margin: 1rem 0;
`


const IconMenu = styled.div<{ theme: TTheme }>`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    transition: all .3s linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};

    &:hover {
        background-color: ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    }

`

const ContainerHome = styled.div<{ theme: TTheme }>`
    width: 55px;
    height: 55px;
    border-radius: .5rem;
    // background-color: ${props => props.theme === 'light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    display: flex;
    align-items: center;
    justify-content:center;
`

interface ISideMenu {
    open: boolean
}

const SideMenu = ({ open }: ISideMenu) => {
    const [openMenu, setOpen] = useState(open);
    const { fixedMenu } = useFixedMenu();

    useEffect(() => setOpen(open), [open])
    const location = useLocation();

    const { theme } = useTheme();

    const isSelected = (link: string) => {
        return location.pathname.startsWith(link);
    };

    return (
        <Container
            open={openMenu}
            theme={theme}
        >
            <Menu
                onMouseEnter={() => open ? null : fixedMenu ? null : setOpen(true)}
                onMouseLeave={() => open ? null : fixedMenu ? null : setOpen(false)}
            >
                <ItemMenu
                    open={openMenu}
                    icone={IconeCommandCurrent}
                    iconeColodiro={IconeCommandColorido}
                    label="Command Center"
                    link="/cc/dashboard"
                    selecionado={isSelected('/cc')}
                />
                <ItemMenu
                    open={openMenu}
                    icone={sensebotCurrent}
                    iconeColodiro={sensebotColorido}
                    label="Sensebot"
                    link="/sensebot/chat"
                    selecionado={isSelected('/sensebot')}
                />
                <ItemMenu
                    open={openMenu}
                    icone={radCurrent}
                    iconeColodiro={radColorido}
                    label="RAD"
                    link="/rad"
                    selecionado={isSelected('/rad')}
                />
                <ItemMenu
                    open={openMenu}
                    icone={recebiveisCurrent}
                    iconeColodiro={recebiveisColorido}
                    label="Nexus"
                    link="/nexus"
                    selecionado={isSelected('/nexus')}
                />
                <ItemMenu
                    open={openMenu}
                    icone={consultoriaCurrent}
                    iconeColodiro={consultoriaColorido}
                    label="Consultoria"
                    link="/consultoria/projetos"
                    selecionado={isSelected('/consultoria')}
                />
            </Menu>
        </Container>
    );
}

export default SideMenu;